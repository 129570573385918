import Newsletter from '../../../components/Newsletter'
import { useRouter } from 'next/router'
import axios from 'axios';
import { useEffect } from 'react';

export const NewsletterPage = props => {
  const router = useRouter()

  useEffect(() => {
    if(router.query['subdomain'] === 'www') {
      router.push('https://m-newsletter.com')
      return
    }

    if (props.notFound) {
      router.push('/404');
    }
  }, [props.notFound, router]);

  if (props.notFound) {
    return null;
  }

  return(
    <Newsletter
      subdomain={router.query['subdomain']}
      data={props.data}
      key={router.asPath}
    />
  )
}
export default NewsletterPage

export async function getStaticProps({ params }) {

  if (params.subdomain.includes('m-newsletter')) {
    return {
      props: {
        notFound: true
      },
      revalidate: 60,
    }
  }

  try {
    const res = await axios.get(
      process.env.NEXT_PUBLIC_FASTLY + '/api/v1/newsletters/' + params.subdomain + '?view=top'
    )
    return {
      props: {
        data: { newsletter: res.data} ,
      },
      revalidate: 3600,
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return {
        props: {
          notFound: true
        },
        revalidate: 60,
      };
    }
  }

}

export async function getStaticPaths() {
  return { paths: [], fallback: 'blocking' }
}
