import React,
  { useState, useEffect, useRef, useContext }
from 'react';
import Link from 'next/link'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useQuery, useManualQuery } from 'graphql-hooks'
import PagesMenu from './common/PagesMenu'
import HeaderButtons from './common/HeaderButtons'
import PaidButton from './back_numbers/PaidButton';
import Footer from './common/Footer'
import { postResponsiveImages, userResponsiveImage } from './common/Utils';
import {
  Button,
} from "@material-tailwind/react";

const SUBSCRIBED_QUERY = `
  query fetchNewsletter($subdomain: String) {
    newsletter(subdomain: $subdomain) {
      subscribed
    }
  }
`

export const Newsletter = props => {
  const [bulk_list, setBulkList] = useState([]);
  const [firstCards, setFirstCards] = useState([]);
  const [secondCards, setSecondCards] = useState([]);
  const [thirdCards, setThirdCards] = useState([]);
  const [page, setPage] = useState(1);
  const [page2, setPage2] = useState(1);
  const router = useRouter()

  const data = props.data
  const [
    fetchNewsletter, { loading: nLoading, error: nError, data: nData }
  ] = useManualQuery(SUBSCRIBED_QUERY, {
    variables: { subdomain: props.subdomain },
    useCache: false,
  })

  useEffect(() => {
    if(props.subdomain === 'www') {
      router.push('https://m-newsletter.com')
    }
  }, []);

  useEffect(() => {
    if(data.newsletter && bulk_list.length == 0) {
      const list = []
      for(const b of data.newsletter.bulkList) {
        list.push(
          <div className='my-4 hover:underline'>
            <Link href={`/backNumbers/${b.month}`} prefetch={false}>
              {b.month.replace('-', '年')}月
            </Link>
          </div>
        )
      }
      setBulkList(list)
    }
    if(nData?.newsletter) return

    fetchNewsletter()
  }, [data?.newsletter]);

  useEffect(() => {
    if(!data) return

    cards_month1()
  }, [data, page]);

  if (!data || !data.newsletter) return <div>Loading</div>

  const newsletter = data.newsletter

  const top_card = () => {
    if(
      !newsletter ||
        !newsletter.monthlyBulks[0] ||
        !newsletter.monthlyBulks[0]?.posts[0]
      ) return null

    const post = newsletter.monthlyBulks[0].posts[0]

    return (
      <>
        <Link className="md:flex md:flex-row h-fit" href={`/posts/${post.id}`}>
          <div className="basis-1/2 bg-gray-100">
            <img
              src={post.thumbnail}
              alt="post"
              className="object-cover w-full h-60 md:h-full md:max-h-80 object-cover mx-auto bg-gray-100 md:py-8"
            />
          </div>
          <div className="basis-1/2 p-4 bg-gray-100">
            <div className="text-2xl my-4 break-all ">{post.title}</div>
            <div className="text-gray-600 mb-4 text-sm break-all  ">
              {post.subtitle}
            </div>
            <div className="text-gray-600 mb-4 text-center text-sm w-full flex items-center ">
              <div className="grow" />
              {post.paid && (
                <div className="w-fit px-2 rounded text-white bg-gray-600 text-xs mr-2">
                  有料記事
                </div>
              )}
              <div className="w-fit text-gray-600 ">{post.publishedAt}</div>
              <div className="grow" />
            </div>
          </div>
        </Link>
      </>
    );
  }

  const cards_month1 = () => {
    if(
        !newsletter.monthlyBulks[0] ||
        !newsletter.monthlyBulks[0].posts[1]
      ) return null

    let b = newsletter.monthlyBulks[0].posts.slice(1, 26 * page).map(post => card(post))
    if(newsletter.monthlyBulks[0].posts.length > 26 * page) {
      b.push(
        <div className="w-full text-center">
          <Button
            onClick={(e) => {setPage(page+1)}}
            variant="outlined"
            className="mx-auto w-fit  border-gray-400 rounded font-normal"
          >
            続きを読みこむ
          </Button>
        </div>
      );

    }
    setFirstCards(b)
  }

  const cards_month_2nd = () => {
    if(newsletter.monthlyBulks.length < 2) return

    const max = newsletter.monthlyBulks[0].posts.length > 26 ? 0 : 26 - newsletter.monthlyBulks[0].posts.length
    if(max == 0) return
    
    const b = []
    const bulk = newsletter.monthlyBulks[1]

    if(bulk.posts[0]) {
      b.push(
        <>
          <div className="ml-2 md:ml-0 text-xl px-3 mt-10 mb-4 border border-gray-400 rounded w-fit py-2 ">
            {bulk.month.replace("-", "年")}月
          </div>
          <hr className="my-10" />
          {bulk.posts.slice(0, max).map((post) => card(post))}

          {bulk.posts.length > max && (
            <div className="w-full text-center">
              <Link href={`/backNumbers/${bulk.month}`} className='w-fit'>
                <Button
                  variant="outlined"
                  className="mx-auto w-fit  border-gray-400 rounded font-normal"
                >
                  続きを読む
                </Button>
              </Link>
            </div>
          )}
          <PaidButton monthlyBulk={bulk} user={newsletter.user} />
        </>
      );
    }
    // if(bulk.posts.length > max) {
    //   b.push(
    //     <div className='ml-2 md:ml-0 text-xl px-3 mt-10 mb-4 border border-gray-400 rounded w-fit py-2 '>
    //       全ての記事
    //     </div>
    //   )
    // }
   
    return b
  }

  const cards_month_3rd = () => {
    if(newsletter.monthlyBulks.length < 3) return

    const previous = newsletter.monthlyBulks[0].posts.length + newsletter.monthlyBulks[1].posts.length
    const max = previous > 26 ? 0 : 26 - previous
    if(max == 0) return
    
    const b = []
    const bulk = newsletter.monthlyBulks[2]

    if(bulk.posts[0]) {
      b.push(
        <>
          <div className='ml-2 md:ml-0 text-xl px-3 mt-10 mb-4 border border-gray-400 rounded w-fit py-2 '>
            {bulk.month.replace('-', '年')}月
          </div>
          <hr className='my-10' />
          {bulk.posts.slice(0, max).map(post => card(post))}
          <PaidButton monthlyBulk={bulk} user={newsletter.user} />
        </>
      )
    }
    return b
  }

  const card = (post) => {
    return (
      <>
        <Link href={`/posts/${post.id}`} className="flex flex-row px-3 min-h-48 ">
          <div className="grow md:pr-4">
            <div className="flex flex-row ">
              <div className="text-xl grow break-all pr-2 md:pr-0">
                {post.title}
              </div>
              <div className="block md:hidden basis-1/4 flex-none">
                {postResponsiveImages(post, "object-cover bg-white h-20 w-full rounded-lg")}
              </div>
            </div>
            <div className="text-sm text-gray-500 mt-4 break-all">
              {post.subtitle}
            </div>

            <div className="mt-4 w-full flex items-center ">
              {post.paid && (
                <div className="w-fit px-2 rounded text-white bg-gray-600 text-xs mr-2">
                  有料記事
                </div>
              )}
              <div className="text-gray-500 text-sm">{post.publishedAt}</div>
            </div>
          </div>
          <div className="hidden md:block flex-none basis-1/4">
            {postResponsiveImages(post, "object-cover w-44 bg-white h-40 rounded-lg")}
          </div>
        </Link>
        <hr className="my-10" />
      </>
    );
  }


  const priced_card = () => {
    if(
      !newsletter ||
        !newsletter.pricedPosts[0]
      ) return null

    const post = newsletter.pricedPosts[0]

    return (
      <>
        <Link className="md:flex md:flex-row h-fit" href={`/posts/${post.id}`}>
          <div className="basis-1/2 bg-gray-100">
            {postResponsiveImages(post, "object-cover w-full h-60 md:h-full object-cover mx-auto bg-gray-100 md:py-8")}
          </div>
          <div className="basis-1/2 p-4 bg-gray-100">
            <div className="text-2xl my-4 break-all ">{post.title}</div>
            <div className="text-gray-600 mb-4 text-sm break-all  ">
              {post.subtitle}
            </div>
            <div className="text-gray-600 mb-4 text-center text-sm w-full flex items-center ">
              <div className="grow" />
              <div className="w-fit px-2 rounded text-white bg-gray-600 text-xs mr-2">
                有料コンテンツ
              </div>
              <div className="w-fit text-gray-600 ">{post.publishedAt}</div>
              <div className="grow" />
            </div>
          </div>
        </Link>
        <hr className="my-10" />
      </>
    );
  }

  return (
    <>
      <Head>
        {process.env.NEXT_PUBLIC_ENV !== "production" && (
          <meta name="robots" content="noindex" />
        )}

        <title>{`${newsletter?.title} | みんなのニュースレター`}</title>
        <meta
          property="twitter:title"
          content={`${newsletter?.title} | みんなのニュースレター`}
        />
        <meta
          name="description"
          content={`${newsletter?.subtitle} | みんなのニュースレター`}
        />
        <meta
          property="twitter:description"
          content={`${newsletter?.subtitle} | みんなのニュースレター`}
        />
        <meta
          property="og:description"
          content={`${newsletter?.subtitle} | みんなのニュースレター`}
        />
        <meta name="twitter:card" content="summary" />
        <meta property="og:image" content={newsletter?.user?.ogp} />
        <meta property="twitter:image" content={newsletter?.user?.ogp} />
      </Head>

      <div className="w-full border-b relative flex items-center justify-center h-16">
        {userResponsiveImage(newsletter.user, "ml-1 md:ml-8 object-cover w-12 h-12 p-2 rounded-full bg-white ")}
        <h1 className="py-0 my-0 font-normal text-sm md:text-xl grow truncate ">
          {newsletter?.title}
        </h1>
        <HeaderButtons subscribed={nData?.newsletter?.subscribed} id={newsletter.id} />
      </div>
      <PagesMenu newsletter={newsletter} />

      <div className="max-w-2xl mx-auto mt-8">
        {top_card()}

        {newsletter?.monthlyBulks[0]?.posts[1] && (
          <>
            <div className="ml-2 md:ml-0 text-xl px-3 mt-14 mb-4 border border-gray-400 rounded w-fit py-2 ">
              {newsletter.monthlyBulks[0].month.replace("-", "年")}月
            </div>
            <hr className="my-10" />
          </>
        )}
        {firstCards}

        {cards_month_2nd()}

        {cards_month_3rd()}

        {bulk_list.length > 0 && !newsletter?.monthlyBulksLastPage && (
          <>
            <div className="my-16 px-3 flex">
              <div className="grow" />
              <Link
                href={data.newsletter ? `/backNumbers/${data.newsletter.bulkList.at(3)?.month}` : '/'}
                className="border border-gray-400 rounded w-15 px-3 py-4 text-xs hover:bg-gray-200"
              >
                次のページ　＞
              </Link>
            </div>
            <hr className="my-10" />
          </>
        )}

        {bulk_list.length > 0 && (
          <>
            <div className="my-16 px-3 ">
              <div className="ml-2 md:ml-0 text-xl px-3 mt-10 mb-8 border border-gray-400 rounded w-fit py-2 ">
                月別アーカイブ
              </div>
              <div className="ml-2 md:ml-0">{bulk_list}</div>
            </div>
            <hr className="my-10" />
          </>
        )}

        {priced_card()}

        <div className="px-3 md:px-0">
          {userResponsiveImage(newsletter.user, "object-cover w-12 h-12 p-2 rounded-full bg-white ")}
          <div className="h-full ">{newsletter?.title}</div>
          <div className="text-sm text-gray-500 mt-4">
            {newsletter?.subtitle}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );

}

export default Newsletter;
